import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { assistantFont } from './AssistantFont.js';
import translations from '../translations.js';

const reverseString = (str) => str.split('').reverse().join('');

const ContractGenerator = async ({ terms, eventDetails, user, signature, language, headerImage }) => {
  const pdf = new jsPDF({
    orientation: 'p',
    format: 'a4',
  });

  pdf.addFileToVFS('Assistant-Regular.ttf', assistantFont);
  pdf.addFont('Assistant-Regular.ttf', 'Assistant', 'normal');

  const pageWidth = pdf.internal.pageSize.width || pdf.internal.pageSize.getWidth();
  const pageHeight = pdf.internal.pageSize.height || pdf.internal.pageSize.getHeight();
  const margin = 10;
  const imgHeight = 40;
  const bulletWidth = 5;

  // Add the header image
  pdf.addImage(headerImage, 'JPEG', 0, 0, pageWidth, imgHeight);

  // Adding the terms text to the PDF
  pdf.setFont(language === 'he' ? 'Assistant' : 'helvetica');
  pdf.setFontSize(8);
  pdf.setTextColor(0, 0, 0);

  let y = imgHeight + margin;
  terms.forEach((term, index) => {
    if (y > pageHeight - 70) {
      pdf.addPage();
      y = margin + imgHeight;
      pdf.addImage(headerImage, 'JPEG', 0, 0, pageWidth, imgHeight);
    }
    const text = language === 'he' ? reverseString(term) : term;
    const bullet = language === 'he' ? reverseString(`-`) : `-`;

    const splitText = pdf.splitTextToSize(text, pageWidth - margin * 2 - bulletWidth);
    splitText.forEach((line, i) => {
      if (language === 'he') {
        pdf.text(bullet, pageWidth - margin - 20, y + (i * 6), { align: 'right', baseline: 'top' });
        pdf.text(line, pageWidth - margin - bulletWidth, y + (i * 6), { align: 'right', baseline: 'top' });
      } else {
        pdf.text(bullet, margin, y + (i * 6), { align: 'left', baseline: 'top' });
        pdf.text(line, margin + bulletWidth, y + (i * 6), { align: 'left', baseline: 'top' });
      }
    });
    y += splitText.length * 6;
  });

  // Switch to normal font for user details and commitment statement
  pdf.setFont('helvetica');
  pdf.setFontSize(10);

  const eventDate = eventDetails.date && eventDetails.date.seconds ? new Date(eventDetails.date.seconds * 1000).toLocaleDateString('en-GB') : 'N/A';
  const userDetails = `
    Name: ${user.displayName}
    Phone Number: ${eventDetails.phone}
    Email: ${eventDetails.email}
    Creation Date: ${new Date().toLocaleDateString('en-GB')}
    Event Date: ${eventDetails.date}
    Event Description: ${eventDetails.description}
  `;
  const commitmentText = "By Signing below, I agree to the following terms";

  if (y > pageHeight - 70) {
    pdf.addPage();
    y = margin;
  }

  pdf.autoTable({
    startY: y + 10,
    margin: { top: 10, left: margin, right: margin },
    body: [
      [{ content: userDetails, styles: { halign: 'left' } }],
      [{ content: commitmentText, styles: { halign: 'left' } }]
    ],
    theme: 'plain',
    styles: { cellPadding: 2, fontSize: 10, cellWidth: 'auto' }
  });

  // Adding the signature image
  const imgProps = pdf.getImageProperties(signature);
  const sigHeight = (imgProps.height * 50) / imgProps.width;
  pdf.addImage(signature, 'PNG', pageWidth / 2 - 25, pdf.lastAutoTable.finalY, 50, sigHeight);

  const pdfBlob = pdf.output('blob');
  return pdfBlob;
};

export default ContractGenerator;
