import React, { useState, forwardRef, useImperativeHandle } from 'react';
import '../styles/Popup.css';
import { useLanguage } from '../LanguageContext';
import translations from '../translations.js';

const Popup = forwardRef((_, ref) => {
  const [visible, setVisible] = useState(false);
  const [messages, setMessages] = useState([]);
  const [onConfirm, setOnConfirm] = useState(null);
  const [isNotification, setIsNotification] = useState(true);
  const { language } = useLanguage();

  useImperativeHandle(ref, () => ({
    notifyUser: (msgs) => {
      return new Promise((resolve) => {
        setMessages(Array.isArray(msgs) ? msgs : [msgs]);
        setIsNotification(true);
        setVisible(true);
        setOnConfirm(() => resolve);
      });
    },
    askUser: (msg, callback) => {
      setMessages([msg]);
      setOnConfirm(() => callback);
      setIsNotification(false);
      setVisible(true);
    },
    hide: () => {
      setVisible(false);
    }
  }));

  const handleClose = () => {
    setVisible(false);
    if (onConfirm) {
      onConfirm(false);
    }
  };

  const handleConfirm = () => {
    setVisible(false);
    if (onConfirm) {
      onConfirm(true);
    }
  };

  if (!visible) {
    return null;
  }

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        {messages.map((msg, index) => (
          <React.Fragment key={index}>
            {msg.split('\n').map((line, lineIndex) => (
              <p key={lineIndex}>{line}<br/></p>
            ))}
          </React.Fragment>
        ))}
        <div className="popup-buttons">
          {isNotification ? (
            <button onClick={handleClose}>{translations[language].confirm}</button>
          ) : (
            <>
              <button onClick={() => setVisible(false)}>{translations[language].cancel}</button>
              <button onClick={handleConfirm}>{translations[language].confirm}</button>
            </>
          )}
        </div>
      </div>
    </div>
  );
});

export const usePopup = () => {
  const ref = React.useRef();

  const notifyUser = (messages) => {
    if (ref.current) {
      return ref.current.notifyUser(messages);
    }
  };

  const askUser = (message) => {
    return new Promise((resolve) => {
      if (ref.current) {
        ref.current.askUser(message, resolve);
      }
    });
  };

  const hide = () => {
    if (ref.current) {
      ref.current.hide();
    }
  };

  return { notifyUser, askUser, hide, setPopupInstance: (instance) => (ref.current = instance) };
};

export default Popup;
