import React, { useState, useEffect, useRef } from 'react';
import { getAllEvents, updateEventStatus, getMembers, updateEventPaid } from '../firebase';
import '../styles/ManagerDashboard.css';
import { useLanguage } from '../LanguageContext';
import translations from '../translations';
import Popup, { usePopup } from '../components/Popup.js';
import CloseButton from '../components/CloseButton.js';
import sendEmail from '../components/EmailSender.js';

const ManagerDashboard = () => {
  const [events, setEvents] = useState([]);
  const [members, setMembers] = useState([]);
  const [view, setView] = useState('events');
  const [statusFilter, setStatusFilter] = useState('all');
  const [showInvitePopup, setShowInvitePopup] = useState(false);
  const [inviteEmail, setInviteEmail] = useState('');
  const { language } = useLanguage();
  const popupRef = useRef();
  const { notifyUser, setPopupInstance, askUser } = usePopup();

  const initialEventStatus = useRef({}); // To keep track of initial statuses

  useEffect(() => {
    setPopupInstance(popupRef.current);
  }, [setPopupInstance]);

  useEffect(() => {
    const fetchEvents = async () => {
      const eventsData = await getAllEvents();
      setEvents(eventsData);
      const initialStatus = {};
      eventsData.forEach(event => {
        initialStatus[event.id] = event.status;
      });
      initialEventStatus.current = initialStatus;
    };

    const fetchMembers = async () => {
      const membersData = await getMembers();
      setMembers(membersData);
    };

    fetchEvents();
    fetchMembers();
  }, []);

  const handleStatusChange = (id, newStatus, type) => {
    if (type === 'event') {
      setEvents(prevEvents =>
        prevEvents.map(event =>
          event.id === id ? { ...event, status: newStatus } : event
        )
      );
    } else if (type === 'member') {
      setMembers(prevMembers =>
        prevMembers.map(member =>
          member.id === id ? { ...member, activeMembership: newStatus } : member
        )
      );
    }
  };

  const handleSaveChanges = async () => {
    for (const event of events) {
      const initialStatus = initialEventStatus.current[event.id];
      const newStatus = event.status;

      if (initialStatus !== newStatus) {
        await updateEventStatus(event.id, newStatus);
        let emailMessage = '';
        let emailSubject = '';
        if (newStatus === 'cancel-request') {
          notifyUser(translations[language].cancelRequestError);
          return;
        }

        if (
          (initialStatus === 'awaiting-files' || initialStatus === 'in-progress') &&
          newStatus === 'approved-deposit'
        ) {
          emailSubject = translations[language].emailApprovedDepositSubject;
          emailMessage = translations[language].emailApprovedDepositMessage;
        } else if (
          (initialStatus === 'awaiting-files' || initialStatus === 'in-progress' || initialStatus === 'approved-deposit') &&
          newStatus === 'approved-paid'
        ) {
          emailSubject = translations[language].emailApprovedPaidSubject;
          emailMessage = translations[language].emailApprovedPaidMessage;
        } else if (newStatus === 'canceled' && initialStatus === 'cancel-request') {
          emailSubject = translations[language].emailCanceledSubject;
          emailMessage = translations[language].emailCanceledMessage;
        } else if (newStatus === 'rejected') {
          emailSubject = translations[language].emailRejectedSubject;
          emailMessage = translations[language].emailRejectedMessage;
        } else if (initialStatus === 'approved-deposit' && newStatus === 'approved-paid') {
          emailSubject = translations[language].emailFullPaymentReceivedSubject;
          emailMessage = translations[language].emailFullPaymentReceivedMessage;
        } else if (newStatus === 'awaiting-files') {
          emailSubject = translations[language].emailAwaitingFilesSubject;
          emailMessage = translations[language].emailAwaitingFilesMessage;
        }

        if (emailMessage) {
          const EmailParams = {
            subject: emailSubject,
            user_name: event.fullName,
            to_email: event.email,
            message: emailMessage,
            linkTitle: translations[language].personalAreaTitle,
            link: 'https://yina.org/personalArea',
          };
          sendEmail(EmailParams);
        } else {
          notifyUser("No email sent");
        }
      }
    }
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return translations[language].noDate;
    const date = timestamp.toDate();
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  const filteredEvents = events.filter(event => {
    if (statusFilter === 'all') return true;
    return event.status === statusFilter;
  });

  const handleInvite = async () => {
    if (inviteEmail) {
      const EmailParams = {
        subject: 'Young Israel Member Invite',
        user_name: inviteEmail.split('@')[0],
        to_email: inviteEmail,
        message: translations[language].inviteMessage,
        linkTitle: translations[language].appTitle,
        link: 'https://yina.org',
      };
      console.log(EmailParams);
      await sendEmail(EmailParams);
      setShowInvitePopup(false);
      notifyUser(translations[language].inviteSent);
    } else {
      notifyUser(translations[language].emailRequired);
    }
  };

  const handleUpdatePaid = async (eventId, currentPaid) => {
    const newPaid = prompt(translations[language].enterNewPaidValue, currentPaid || '');
    if (newPaid !== null) {
      if (isNaN(newPaid)) {
        notifyUser(translations[language].invalidPaidValue);
        return;
      }
      try {
        await updateEventPaid(eventId, Number(newPaid));
        setEvents(prevEvents =>
          prevEvents.map(event =>
            event.id === eventId ? { ...event, paid: Number(newPaid) } : event
          )
        );
        notifyUser(translations[language].paidValueUpdated);
      } catch (error) {
        console.error('Error updating paid value:', error);
        notifyUser(translations[language].paidValueUpdateError);
      }
    }
  };

  return (
    <div className="manager-dashboard">
      <CloseButton />
      <div className="dashboard-header">
        <h2>{translations[language].managerDashboardTitle}</h2>
        <div>
          <select value={view} onChange={(e) => setView(e.target.value)}>
            <option value="events">{translations[language].events}</option>
            <option value="members">{translations[language].members}</option>
          </select>
          {view === 'events' && (
            <select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
              <option value="all">{translations[language].allEvents}</option>
              <option value="awaiting-files">{translations[language].awaitingFiles}</option>
              <option value="in-progress">{translations[language].inProgress}</option>
              <option value="approved-deposit">{translations[language].approvedDeposit}</option>
              <option value="approved-paid">{translations[language].approvedPaid}</option>
              <option value="cancel-request">{translations[language].cancelRequest}</option>
              <option value="rejected">{translations[language].rejected}</option>
              <option value="canceled">{translations[language].canceled}</option>
              <option value="completed">{translations[language].completed}</option>
            </select>
          )}
          {view === 'members' && (
            <button className="invite-button" onClick={() => setShowInvitePopup(true)}>
              {translations[language].inviteMember}
            </button>
          )}
          <button className="save-changes-button" onClick={handleSaveChanges}>
            {translations[language].saveChanges}
          </button>
        </div>
      </div>
      {view === 'events' ? (
        <table className="events-table">
          <thead>
            <tr>
              <th>{translations[language].eventDate}</th>
              <th>{translations[language].fullName}</th>
              <th>{translations[language].phoneNumber}</th>
              <th>{translations[language].email}</th>
              <th>{translations[language].description}</th>
              <th>{translations[language].contract}</th>
              <th>{translations[language].paymentFile}</th>
              <th>{translations[language].cost}</th>
              <th>{translations[language].credit}</th>
              <th>{translations[language].status}</th>
            </tr>
          </thead>
          <tbody>
            {filteredEvents.map((event) => (
              <tr key={event.id}>
                <td>{formatDate(event.date)}</td>
                <td>{event.fullName}</td>
                <td>{event.phone}</td>
                <td>{event.email}</td>
                <td>{event.description}</td>
                <td>
                  {event.signedContract ? (
                    <a href={event.signedContract} target="_blank" rel="noopener noreferrer">
                      {translations[language].viewContract}
                    </a>
                  ) : (
                    translations[language].noFile
                  )}
                </td>
                <td>
                  {event.uploadedFile ? (
                    <a href={event.uploadedFile} target="_blank" rel="noopener noreferrer">
                      {translations[language].viewFile}
                    </a>
                  ) : (
                    translations[language].noFile
                  )}
                </td>
                <td>{event.cost}</td>
                <td>
                  <div>{event.paid || '0'}</div>
                  
                </td>
                <td className='action-buttons'>
                  <select
                    value={event.status}
                    onChange={(e) => handleStatusChange(event.id, e.target.value, 'event')}
                  >
                    <option value="awaiting-files">{translations[language].awaitingFiles}</option>
                    <option value="in-progress">{translations[language].inProgress}</option>
                    <option value="approved-deposit">{translations[language].approvedDeposit}</option>
                    <option value="approved-paid">{translations[language].approvedPaid}</option>
                    <option value="cancel-request">{translations[language].cancelRequest}</option>
                    <option value="canceled" >{translations[language].canceled}</option>
                    <option value="rejected">{translations[language].rejected}</option>
                    <option value="completed">{translations[language].completed}</option>
                    <option value="remove">{translations[language].remove}</option>
                  </select>
                  <button onClick={() => handleUpdatePaid(event.id, event.paid)}>
                    {translations[language].updatePaid}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <table className="members-table">
          <thead>
            <tr>
              <th>{translations[language].fullName}</th>
              <th>{translations[language].partner}</th>
              <th>{translations[language].email}</th>
              <th>{translations[language].phoneNumber}</th>
              <th>{translations[language].address}</th>
              <th>{translations[language].membership}</th>
            </tr>
          </thead>
          <tbody>
            {members.map((member) => (
              <tr key={member.id}>
                <td>{member.fullName}</td>
                <td>{member.partner ? member.partner : " "}</td>
                <td>{member.email}</td>
                <td>{member.phoneNumber}</td>
                <td>{member.address}</td>
                <td>
                  <select 
                    value={member.activeMembership ? 'active' : 'inactive'}
                    onChange={(e) => handleStatusChange(member.id, e.target.value === 'active', 'member')}
                  >
                    <option value="active">{translations[language].active}</option>
                    <option value="inactive">{translations[language].inactive}</option>
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {showInvitePopup && (
        <div className="invite-popup">
          <h3>{translations[language].inviteMember}</h3>
          <input
            type="email"
            placeholder={translations[language].email}
            value={inviteEmail}
            onChange={(e) => setInviteEmail(e.target.value)}
          />
          <button onClick={handleInvite}>{translations[language].sendInvite}</button>
          <button onClick={() => setShowInvitePopup(false)}>{translations[language].cancel}</button>
        </div>
      )}
      <Popup ref={popupRef} />
    </div>
  );
};

export default ManagerDashboard;
