import React from 'react';
import { signInWithGoogle } from '../firebase';
import '../styles/Login.css';
import googleIcon from '../icons8-google-96.png'; // Import the Google icon
import { useLanguage } from '../LanguageContext';
import translations from '../translations';

const Login = ({ onLogin }) => {
  const { language } = useLanguage();
  const handleLogin = async () => {
    try {
      const user = await signInWithGoogle();
      onLogin(user);
    } catch (error) {
      console.error("Error logging in: ", error);
    }
  };

  return (
    <div className="login-page">
      <div className="login">
        <button onClick={handleLogin}>
          {translations[language].enter}
        </button>
      </div>
    </div>
  );
};

export default Login;
