import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useLocation, useNavigate } from 'react-router-dom';
import { uploadFile, updateEventFile, getMemberDetails, getUpcomingEvents } from '../firebase';
import { useLanguage } from '../LanguageContext';
import translations from '../translations';
import '../styles/PaymentInstruction.css';
import sendEmail from './EmailSender.js';
import Popup, { usePopup } from '../components/Popup';


const ManagerEmail = 'youngisraelofnevealiza@gmail.com';

const PaymentInstruction = () => {
  const { language } = useLanguage();
  const navigate = useNavigate();
  const location = useLocation();
  const { eventId, user, eventDetails } = location.state; // Access eventId, user, and eventDetails from location state
  const [event, setEvent] = useState(eventDetails); // State to store event details
  const [file, setFile] = useState(null);
  const [awaitingFilesEventId, setAwaitingFilesEventId] = useState(eventId);
  const [memberDetails, setMemberDetails] = useState({});
  const [events, setEvents] = useState([]);
  const { notifyUser } = usePopup();

  useEffect(() => {
    const fetchData = async () => {
      const details = await getMemberDetails(user.email);
      const userEvents = await getUpcomingEvents(user.email);

      setMemberDetails(details || {});
      setEvents(userEvents || []);

      const awaitingFilesEvent = userEvents.find(event => event.status === 'awaiting files');
      if (awaitingFilesEvent) {
        setAwaitingFilesEventId(awaitingFilesEvent.id);
      }
    };

    fetchData();
  }, [user.email]);

  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handlePayLater = () => {
    navigate('/personalArea');
  };
  
  const handleFileUpload = async () => {
    if (file && awaitingFilesEventId) {
      try {
        const fileUrl = await uploadFile(file, user.uid);
        await updateEventFile(awaitingFilesEventId, fileUrl);
        notifyUser(translations[language].fileUploadedSuccess);
        setEvents((prevEvents) =>
          prevEvents.map((event) =>
            event.id === awaitingFilesEventId ? { ...event, uploadedFile: fileUrl, status: 'in progress' } : event
          )
        );
        setFile(null);
        setAwaitingFilesEventId(null);

        const ManagerEmailParams = {
          subject: "New Event needs Approval",
          user_name: 'Manager',
          to_email: ManagerEmail,
          message: "Please Verify the new event and file uploaded by the user and approve it - ",
          linkTitle: translations[language].managerDashboardTitle,
          link: 'https://yina.org/managerDashboard'
        };
        sendEmail(ManagerEmailParams);
        navigate('/personalArea');
      } catch (error) {
        console.error('Error uploading file and updating event:', error);
        notifyUser(translations[language].errorMsg);
      }
    }
  };

  return (
    <div className={`payment-instruction ${language === 'he' ? 'rtl' : 'ltr'}`}>
      <h3>{translations[language].transferTitle}</h3>
      <p>{translations[language].bookingPayment}</p>
      <div className="transfer">
        <h3>{translations[language].bankTransferDetails}</h3>
        <p>{translations[language].bankName}</p>
        <p>{translations[language].accountName}</p>
        <p>{translations[language].branch}</p>
        <p>{translations[language].accountNumber}</p>
        {event && (
          <>
            <p>{translations[language].transferAmount} : {event.status === 'approved-deposit' ? event.cost-(event.cost*0.25) :event.cost} {translations[language].currency}</p>
            <p>{translations[language].transferCause} : {event.potentialMember ? translations[language].venueMembership : translations[language].venue} </p>
          </>
        )}
      </div>
      <p>{translations[language].transfer_a}</p>
      <h3>{translations[language].uploadFile}</h3>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        {
          isDragActive ?
            <p>{translations[language].dropFilesHere}</p> :
            <p>{translations[language].dragAndDropFiles}</p>
        }
      </div>
      {file && (
        <div className="uploaded-file">
          <p>{file.path} - {file.size} bytes</p>
        </div>
      )}
      <button className="upload-button" onClick={handleFileUpload}>
        {translations[language].finishProcess}
      </button>
      <button className="skip-button" onClick={handlePayLater}>
        {translations[language].skip}
      </button>
      <p>{translations[language].transfer_c}</p>

    </div>
  );
};

export default PaymentInstruction;
