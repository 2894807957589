import React, { useState, useRef, useEffect } from 'react';
import { useLanguage } from '../LanguageContext';
import translations from '../translations';
import SignaturePad from 'react-signature-canvas';
import { storage, addEvent, checkInProgressEvents } from '../firebase';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import sendEmail from './EmailSender.js';
import '../styles/TermsOfUse.css';
import ContractGenerator from './ContractGenerator';
import headerImage from '../youngIsraelBackground.jpg';
import { useNavigate } from 'react-router-dom';
import CloseButton from '../components/CloseButton.js';
import Popup, { usePopup } from '../components/Popup.js';


const TermsOfUse = ({ onAccept, user, eventDetails, onBack }) => {
  const { language } = useLanguage();
  const [currentTermIndex, setCurrentTermIndex] = useState(0);
  const termsPerPage = 6;
  const [isSigning, setIsSigning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSignatureEmpty, setIsSignatureEmpty] = useState(true); // New state for checking if signature is empty
  const sigCanvas = useRef(null);
  const navigate = useNavigate();
  const { askUser, notifyUser, setPopupInstance } = usePopup();


  useEffect(() => {
    const checkExistingEvents = async () => {
      const hasInProgressEvents = await checkInProgressEvents(user.uid);
      if (hasInProgressEvents) {
        notifyUser(translations[language].eventInProgress);
        onBack();
      }
    };

    checkExistingEvents();
  }, [user.uid, language, onBack]);

  const handleAccept = () => {
    if (currentTermIndex + termsPerPage < translations[language].termsList.length) {
      setCurrentTermIndex(currentTermIndex + termsPerPage);
    } else {
      setIsSigning(true);
    }
  };

  const handleReset = () => {
    sigCanvas.current.clear();
    setIsSignatureEmpty(true); // Reset the signature check
  };

  const handleSignatureEnd = () => {
    setIsSignatureEmpty(sigCanvas.current.isEmpty());
  };

  const handleNavigateToPayment = (eventId) => {
    const serializedUser = {
      displayName: user.displayName,
      email: user.email,
      uid: user.uid
    };

    const serializedEventDetails = {
      ...eventDetails,
      userId: user.uid,
      id: eventId
    };

    navigate('/paymentInstructions', { state: { eventId, user: serializedUser, eventDetails: serializedEventDetails } });
  };

  const handleSubmit = async () => {
    if (isSignatureEmpty) {
      notifyUser(translations[language].emptySignature);
      return;}
    setIsLoading(true);
    const signature = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");

    try {
      const pdfBlob = await ContractGenerator({
        terms: translations[language].termsList,
        eventDetails,
        user,
        signature,
        language,
        headerImage
      });

      if (!pdfBlob) {
        throw new Error('Failed to generate contract PDF');
      }

      const pdfRef = ref(storage, `contracts/${user.uid}.pdf`);
      await uploadBytes(pdfRef, pdfBlob);
      const pdfURL = await getDownloadURL(pdfRef);

      const eventWithContract = { ...eventDetails, userId: user.uid, status: 'awaiting-files', signedContract: pdfURL };
      const eventId = await addEvent(eventWithContract);

      const userEmailParams = {
        subject: translations[language].userEmailSubject,
        user_name: user.displayName,
        to_email: user.email,
        message: translations[language].eventBooked,
        linkTitle: translations[language].personalAreaTitle,
        link: 'https://yina.org/personalArea',
      };
      await sendEmail(userEmailParams);

      handleNavigateToPayment(eventId);
    } catch (error) {
      console.error('Error generating contract and uploading:', error);
      notifyUser('Error generating contract and uploading. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='termsContainer'>
      <div className="terms-of-use">
        <CloseButton verify={true} />
        <h2>{translations[language].termsTitle}</h2>
        <div id="terms-content">
          {!isSigning ? (
            <>
              {translations[language].termsList.slice(currentTermIndex, currentTermIndex + termsPerPage).map((term, index) => (
                <p key={index}>{term}</p>
              ))}
              <button className='termsButton' onClick={handleAccept}>{translations[language].accept}</button>
            </>
          ) : (
            <>
              <p>{translations[language].commitment.replace('<user_name>', user.displayName)}</p>
              <div className="signature">
                <SignaturePad 
                  ref={sigCanvas} 
                  canvasProps={{ className: 'signatureCanvas', willReadFrequently: true }} 
                  onEnd={handleSignatureEnd} // Attach the onEnd handler
                />
              </div>
              <p>{translations[language].signBelow}</p>
              <button className='termsButton' onClick={handleReset}>{translations[language].reset}</button>
              <button className='termsButton' onClick={handleSubmit} disabled={isLoading}>
                {isLoading ? 'Signing...' : translations[language].sign}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TermsOfUse;
