import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { getFirestore, collection, doc, getDoc,addDoc, setDoc, updateDoc, query, orderBy, limit, getDocs, where, Timestamp,deleteDoc  } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyAiRUJQicHqjs2xDX3PqhiqU_xiFo57TG4",
  authDomain: "testfirebase-68b9c.firebaseapp.com",
  databaseURL: "https://testfirebase-68b9c-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "testfirebase-68b9c",
  storageBucket: "testfirebase-68b9c.appspot.com",
  messagingSenderId: "519517877807",
  appId: "1:519517877807:web:f23eb9f8657dfa043238da",
  measurementId: "G-26BZBESV4B"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);
const provider = new GoogleAuthProvider();

const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, provider);
    return result.user;
  } catch (error) {
    console.error("Error signing in with Google: ", error);
    throw error;
  }
};

const getMemberDetails = async (email) => {
  try {
    const docRef = doc(firestore, 'members', email);
    const docSnap = await getDoc(docRef);
    if(docSnap.exists()){
      return docSnap.data();
    } else {
      console.log('No such document!');
      return {};
    }
  } catch (error) {
    console.error('Error getting document:', error);
    return {};
  }
};


const saveMemberDetails = async (email, memberDetails) => {
  await setDoc(doc(firestore, 'members', email), memberDetails);
};

const updateMemberDetails = async (email, updates) => {
  const docRef = doc(firestore, 'members', email);
  await updateDoc(docRef, updates);
};

const addEvent = async (event) => {
  const eventRef = doc(collection(firestore, 'events'));
  await setDoc(eventRef, event);
  return { id: eventRef.id, ...event };
};
const addMember = async (memberDetails) => {
  console.log("Adding member:", memberDetails); // Print the details being added for debugging
  const memberRef = firestore.collection('members').doc(memberDetails.email); // Reference to the member document
  await memberRef.set(memberDetails); // Set the member details in Firestore
};


const updateEventStatus = async (eventId, status) => {
  const eventRef = doc(firestore, 'events', eventId);
  await updateDoc(eventRef, { status });
  
  if (status === 'remove') {
    await deleteDoc(eventRef);
  }
};

const updateEventFile = async (eventId, fileUrl) => {
  const eventRef = doc(firestore, 'events', eventId);
  await updateDoc(eventRef, {
    uploadedFile: fileUrl,
    status: 'in-progress'
  });
};

const getUpcomingEvents = async (email) => {
  const q = query(collection(firestore, 'events'), where('email', '==', email));
  const snapshot = await getDocs(q);
  return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

const isDateOccupied = async (date) => {
  const startOfDay = Timestamp.fromDate(new Date(date.setHours(0, 0, 0, 0)));
  const endOfDay = Timestamp.fromDate(new Date(date.setHours(23, 59, 59, 999)));

  const q = query(collection(firestore, 'events'), where('date', '>=', startOfDay), where('date', '<=', endOfDay));
  const snapshot = await getDocs(q);

  if (!snapshot.empty) {
    const event = snapshot.docs[0].data();
    return { occupied: true, status: event.status };
  } else {
    return { occupied: false, status: null };
  }
};

const generateMemberId = async () => {
  const q = query(collection(firestore, 'members'), orderBy('createdAt', 'desc'), limit(1));
  const snapshot = await getDocs(q);
  if (!snapshot.empty) {
    const lastId = snapshot.docs[0].data().memberId;
    const newId = String(parseInt(lastId, 10) + 1).padStart(5, '0');
    return newId;
  }
  return '00001';
};

const uploadFile = async (file, userId) => {
  if (!file || !userId) return;
  const storageRef = ref(storage, `uploads/${userId}/${file.name}`);

  try {
    const snapshot = await uploadBytes(storageRef, file);
    const fileUrl = await getDownloadURL(snapshot.ref);
    return fileUrl;
  } catch (error) {
    console.error("Error uploading file:", error);
    alert("Error uploading file: " + error.message);
  }
};

const getUserRole = async (email) => {
  const docRef = doc(firestore, 'members', email);
  const docSnap = await getDoc(docRef);
  return docSnap.exists() ? docSnap.data().role : null;
};

const checkInProgressEvents = async (email) => {
  const eventsQuery = query(
    collection(firestore, 'events'),
    where('email', '==', email),
    where('status', '==', 'in-progress')
  );
  const eventsSnapshot = await getDocs(eventsQuery);
  return !eventsSnapshot.empty;
};

const getAllEvents = async () => {
  const eventsCollection = collection(firestore, 'events');
  const eventsSnapshot = await getDocs(eventsCollection);
  return eventsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

const getMembers = async () => {
  const membersCollection = collection(firestore, 'members');
  const membersSnapshot = await getDocs(membersCollection);
  return membersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};


const getEventsByEmail = async (email) => {
  const eventsQuery = query(collection(firestore, 'events'), where('email', '==', email));
  const eventsSnapshot = await getDocs(eventsQuery);
  return eventsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

const checkAndAddUserToMembers = async (user) => {
  const membersRef = collection(firestore, 'members');
  const userDocRef = doc(membersRef, user.email);

  const userDocSnapshot = await getDoc(userDocRef);

  if (!userDocSnapshot.exists()) {
    // User not found in 'members' collection, add them
    await setDoc(userDocRef, {
      fullName: user.displayName,
      email: user.email,
      activeMembership: false,
      role: 'user'
    });
    console.log('User added to members collection');
  } else {
    console.log('User already exists in members collection');
  }
};

const updateEventDate = async (eventId, newDate) => {
  const eventRef = doc(firestore, 'events', eventId);
  await updateDoc(eventRef, { date: newDate });
};

const updateEventPaid = async (eventId, newPaidValue) => {
  try {
    const eventRef = doc(firestore, 'events', eventId); // Adjust the collection path as needed
    await updateDoc(eventRef, { paid: newPaidValue });
    console.log(`Event ${eventId} paid value updated to ${newPaidValue}`);
  } catch (error) {
    console.error('Error updating paid value:', error);
    throw new Error('Failed to update paid value');
  }
};

export { auth, firestore, storage, signInWithGoogle, getEventsByEmail, addEvent, addMember, getMemberDetails, saveMemberDetails, 
  updateMemberDetails, updateEventStatus, getUpcomingEvents, isDateOccupied, generateMemberId, uploadFile, getUserRole,
  checkInProgressEvents, getAllEvents, updateEventFile, getMembers, checkAndAddUserToMembers,updateEventDate, updateEventPaid};
