import React, { useState, useCallback, useEffect, useRef } from 'react';
import { getMemberDetails, getUpcomingEvents, updateMemberDetails, updateEventStatus, getAllEvents, updateEventDate } from '../firebase';
import { useLanguage } from '../LanguageContext';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CloseButton from '../components/CloseButton.js';
import sendEmail from './EmailSender.js';
import Popup, { usePopup } from '../components/Popup';
import translations from '../translations';
import '../styles/PersonalArea.css';

const PersonalArea = ({ user }) => {
  const { language } = useLanguage();
  const navigate = useNavigate();
  const [memberDetails, setMemberDetails] = useState({});
  const [events, setEvents] = useState([]);
  const [editingDetails, setEditingDetails] = useState(false);
  const [awaitingFilesEventId, setAwaitingFilesEventId] = useState(null);
  const [awaitedEvent, setAwaited] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [occupiedDates, setOccupiedDates] = useState([]);
  const popupRef = useRef();
  const { notifyUser, setPopupInstance, askUser } = usePopup();

  useEffect(() => {
    setPopupInstance(popupRef.current);
  }, [setPopupInstance]);

  useEffect(() => {
    const fetchData = async () => {
      const details = await getMemberDetails(user.email);
      const userEvents = await getUpcomingEvents(user.email);

      setMemberDetails(details || {});
      setEvents(userEvents || []);

      const awaitingFilesEvent = userEvents.find(event => event.status === 'awaiting-files');
      setAwaited(awaitingFilesEvent);
      if (awaitingFilesEvent) {
        setAwaitingFilesEventId(awaitingFilesEvent.id);
      }
    };

    fetchData();
  }, [user.email]);

  useEffect(() => {
    const fetchOccupiedDates = async () => {
      const events = await getAllEvents();
      setOccupiedDates(events);
    };

    fetchOccupiedDates();
  }, []);

  const onDrop = useCallback((acceptedFiles) => {}, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMemberDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleSaveDetails = async () => {
    await updateMemberDetails(user.email, memberDetails);
    setEditingDetails(false);
    notifyUser(translations[language].detailsUpdated);
  };

  const handleNavigateToPayment = (eventId) => {
    const serializedUser = {
      displayName: user.displayName,
      email: user.email,
      uid: user.uid,
    };

    const serializedEventDetails = {
      ...awaitedEvent,
      userId: user.uid,
      id: eventId,
    };

    navigate('/paymentInstructions', { state: { eventId, user: serializedUser, eventDetails: serializedEventDetails } });
  };

  const handleCancelRequest = async (eventId) => {
    const confirmed = await askUser(translations[language].cancelConfirmation);
    if (!confirmed) return;

    try {
      await updateEventStatus(eventId, 'cancel-request');
      setEvents((prevEvents) =>
        prevEvents.map((event) =>
          event.id === eventId ? { ...event, status: 'cancel-request' } : event
        )
      );
      notifyUser(translations[language].cancelRequestSuccess);
    } catch (error) {
      console.error('Error sending cancel request:', error);
      notifyUser('Error sending cancel request. Please try again later.');
    }
    try {
      const event = events.find(event => event.id === eventId);
      const ManagerEmailParams = {
        subject: 'Cancel Request for Event',
        user_name: user.displayName,
        to_email: 'youngisraelofnevealiza@gmail.com',
        message: `Please cancel the event requested by ${user.displayName}. Event Details: ${event} - Make sure to check if the user has paid and refund if needed. - Thank you!`,
        linkTitle: translations[language].managerDashboardTitle,
        link: 'https://yina.org/managerDashboard',
      };
      sendEmail(ManagerEmailParams);
    } catch (error) {
      console.error('Error sending cancel request email:', error);
    }
  };

  const handleDateChange = async () => {
    try {
      await updateEventDate(selectedEventId, selectedDate);
      setEvents((prevEvents) =>
        prevEvents.map((event) =>
          event.id === selectedEventId ? { ...event, date: { seconds: Math.floor(selectedDate.getTime() / 1000) } } : event
        )
      );
      notifyUser(translations[language].dateChangeSuccess );
      setShowDatePicker(false);
    } catch (error) {
      console.error('Error changing event date:', error);
      notifyUser(translations[language].dateChangeError);
    }
  };

  const handleShowDatePicker = (eventId, currentDate) => {
    setSelectedEventId(eventId);
    setSelectedDate(new Date(currentDate.seconds * 1000));
    setShowDatePicker(true);
  };

  const minDate = new Date();
  minDate.setDate(minDate.getDate() + 5); // Minimum date is 5 days ahead

  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + 1);

  const getDayClassName = (date) => {
    const event = occupiedDates.find(occupiedDate => {
      const occupiedDateObj = new Date(occupiedDate.date.seconds * 1000);
      return date.toDateString() === occupiedDateObj.toDateString();
    });

    if (event) {
      const eventDay = new Date(event.date.seconds * 1000).getDay();
      const currentDay = date.getDay();

      if (eventDay === 5 && (currentDay === 4 || currentDay === 6)) {
        return 'blocked-date';
      } else if (eventDay === 4 && currentDay === 5) {
        return 'blocked-date';
      } else if (eventDay === 6 && currentDay === 5) {
        return 'blocked-date';
      }

      switch (event.status) {
        case 'in-progress':
        case 'awaiting-files':
          return 'occupied-date';
        case 'approved-deposit':
          return 'approved-deposit-date';
        case 'approved-paid':
        case 'completed':
          return 'approved-paid-date';
        default:
          return '';
      }
    }
    return '';
  };

  const handleDateSelect = (date) => {
    if (date.getDay() === 5) {
      notifyUser('You have chosen a Friday morning event, If you meant to have an event on Friday evening chose Saturday.');
    }
    setSelectedDate(date);
  };

  const formatDate = (date) => {
    return new Date(date.seconds * 1000).toLocaleDateString('en-GB');
  };

  return (
    <div className={`personal-area ${language === 'he' ? 'rtl' : 'ltr'}`}>
      <CloseButton />
      <h2>{translations[language].personalAreaTitle}</h2>
      <section className="member-details">
        {editingDetails ? (
          <div className="details-form">
            <input
              type="text"
              name="fullName"
              placeholder={translations[language].fullName}
              value={memberDetails.fullName || ''}
              onChange={handleInputChange}
              required
            />
            <input
              type="text"
              name="partner"
              placeholder={translations[language].partner}
              value={memberDetails.partner || ''}
              onChange={handleInputChange}
              required
            />
            <input
              type="text"
              name="phoneNumber"
              placeholder={translations[language].phoneNumber}
              value={memberDetails.phoneNumber || ''}
              onChange={handleInputChange}
              required
            />
            <input
              type="text"
              name="address"
              placeholder={translations[language].address}
              value={memberDetails.address || ''}
              onChange={handleInputChange}
              required
            />
            <button className="save-button" onClick={handleSaveDetails}>
              {translations[language].save}
            </button>
          </div>
        ) : (
          <div className="details-view">
            <table className="events-table">
              <thead>
                <tr>
                  <th>{translations[language].fullName}</th>
                  <th>{translations[language].partner}</th>
                  <th>{translations[language].phoneNumber}</th>
                  <th>{translations[language].address}</th>
                  <th>{translations[language].membership}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{memberDetails.fullName}</td>
                  <td>{memberDetails.partner}</td>
                  <td>{memberDetails.phoneNumber}</td>
                  <td>{memberDetails.address}</td>
                  <td>{memberDetails.activeMembership ? translations[language].active : translations[language].inactive}</td>
                </tr>
              </tbody>
            </table>
            <button className="edit-button" onClick={() => setEditingDetails(true)}>
              {translations[language].edit}
            </button>
          </div>
        )}
      </section>

      <section className="member-events">
        <h3>{translations[language].myEventsTitle}</h3>
        {events.length > 0 ? (
          <table className="events-table">
            <thead>
              <tr>
                <th>{translations[language].eventDescription}</th>
                <th>{translations[language].eventDate}</th>
                <th>{translations[language].status}</th>
                <th>{translations[language].uploadedFile}</th>
                <th>{translations[language].cost}/{translations[language].leftPaid}</th>
                <th>{translations[language].actions}</th>
              </tr>
            </thead>
            <tbody>
              {events.map((event) => (
                <tr key={event.id}>
                  <td>{event.description}</td>
                  <td>{formatDate(event.date)}</td>
                  <td>
                    {event.status === 'awaiting-files'
                      ? translations[language].awaitingFiles
                      : event.status === 'in-progress'
                      ? translations[language].inProgress
                      : event.status === 'rejected'
                      ? translations[language].rejected
                      : event.status === 'approved-deposit'
                      ? translations[language].approvedDeposit
                      : event.status === 'approved-paid'
                      ? translations[language].approvedPaid
                      : event.status === 'cancel-request'
                      ? translations[language].cancelRequest
                      : event.status}
                  </td>
                  <td>
                    {event.uploadedFile ? (
                      <a href={event.uploadedFile} target="_blank" rel="noopener noreferrer">
                        {translations[language].viewFile}
                      </a>
                    ) : (
                      <span>{translations[language].noFile}</span>
                    )}
                  </td>
                  <td>{event.cost - (event.paid ? event.paid : "0")}/{event.cost}</td>
                  <td className="action-buttons">
                    {(event.status === 'approved-deposit' || event.status === 'in-progress' || event.status === 'awaiting-files') && (
                      <>
                        <button className="pay-now" onClick={() => handleNavigateToPayment(event.id)}>
                          {translations[language].payment}
                        </button>
                      </>
                    )}
                    {
                      (event.status !== 'cancel-request' &&
                      event.status !== 'rejected' &&
                      event.status !== 'completed' &&
                      event.status !== 'cancelled' &&
                      event.date && 
                      (new Date(event.date.seconds * 1000) - new Date()) / (1000 * 60 * 60 * 24) > 5) && (
                        <>
                          <button className="cancel-request" onClick={() => handleCancelRequest(event.id)}>
                            {translations[language].cancelBooking}
                          </button>
                          <button className="change-date" onClick={() => handleShowDatePicker(event.id, event.date)}>
                            {translations[language].changeDate}
                          </button>
                        </>
                      )
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>{translations[language].noEvents}</p>
        )}
      </section>

      {showDatePicker && (
        <div className="date-picker-popup">
          <h3>{translations[language].selectDate}</h3>
          <ReactDatePicker
            inline
            selected={selectedDate}
            onChange={handleDateSelect}
            minDate={minDate}
            maxDate={maxDate}
            dayClassName={getDayClassName}
            calendarClassName="custom-datepicker"
          />
          <button className='date-picker-save' onClick={handleDateChange}>{translations[language].save}</button>
          <button className='date-picker-cancel' onClick={() => setShowDatePicker(false)}>{translations[language].cancel}</button>
        </div>
      )}
      <Popup ref={popupRef} />
    </div>
  );
};

export default PersonalArea;
