import React, { useState, useEffect, useRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/EventBooking.css';
import { isDateOccupied, getEventsByEmail, getAllEvents, getMemberDetails, addMember } from '../firebase.js';
import { useLanguage } from '../LanguageContext';
import translations from '../translations';
import { useNavigate } from 'react-router-dom';
import Popup, { usePopup } from '../components/Popup';
import CloseButton from '../components/CloseButton.js';

const EventBooking = ({ onSubmit, savedDetails, user, onBack }) => {
  const { language } = useLanguage();
  const navigate = useNavigate();
  const [memberDetails, setMemberDetails] = useState({});
  const [selectedDate, setSelectedDate] = useState(savedDetails?.date || new Date());
  const [description, setDescription] = useState(savedDetails?.description || '');
  const [otherDescription, setOtherDescription] = useState('');
  const [fullName, setFullName] = useState(user.displayName || '');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState(user.email || '');
  const [occupied, setOccupied] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dateChecked, setDateChecked] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [occupiedDates, setOccupiedDates] = useState([]);
  const [potentialMember, setPotentialMember] = useState(false);
  const popupRef = useRef();
  const { notifyUser, setPopupInstance } = usePopup();

  useEffect(() => {
    setPopupInstance(popupRef.current);
  }, [setPopupInstance]);

  useEffect(() => {
    const fetchData = async () => {
      const details = await getMemberDetails(user.email);
      setPhone(details.phoneNumber || '');
      setMemberDetails(details || {});
    };
    fetchData();
  }, [user.email]);

  useEffect(() => {
    const checkInProgressEvent = async () => {
      const events = await getEventsByEmail(user.email);
      const inProgressEvent = events.find(event => event.status === 'in-progress' || event.status === 'awaiting-files');
      if (inProgressEvent) {
        await notifyUser(translations[language].singleInProgressEvent);
        navigate('/');
      }
    };

    checkInProgressEvent();
  }, [user.email, language, navigate]);

  useEffect(() => {
    const fetchOccupiedDates = async () => {
      const events = await getAllEvents();
      setOccupiedDates(events);
    };

    fetchOccupiedDates();
  }, []);

  useEffect(() => {
    const checkDate = async () => {
      if (selectedDate) {
        const { occupied, status } = await isDateOccupied(selectedDate, email);
        setOccupied(occupied && status !== 'canceled');
        setDateChecked(true);
      }
    };

    checkDate();
  }, [selectedDate, language, email]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (occupied || isSubmitting) {
      return;
    }
    setIsSubmitting(true);

    const isMember = await getMemberDetails(email).then(details => details && details.activeMembership);
    const isSaturday = selectedDate.getDay() === 6;

    let eventCost;
    const membershipCost = 800;
    if (isSaturday) {
      eventCost = isMember ? 1850 : 2550;
      if (potentialMember) {
        eventCost = membershipCost + 1850;
      }
    } else {
      eventCost = isMember ? 1450 : 1850;
      if (potentialMember) {
        eventCost = membershipCost + 1450;
      }
    }

    const eventDetails = {
      description: description === 'other' ? otherDescription : description,
      fullName,
      phone,
      email,
      date: selectedDate,
      cost: eventCost,
      potentialMember
    };
    await onSubmit(eventDetails);

    setIsSubmitting(false);
  };

  const handleSeePrices = () => {
    const formattedMemberPrices = translations[language].memberPrices.split('/').join('\n');
    const formattedPrices = translations[language].nonMemberPrices.split('/').join('\n');
    notifyUser([formattedMemberPrices, formattedPrices, translations[language].projector]);
  };

  const formatDate = (date) => {
    if (!date) {
      return '';
    }

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const minDate = new Date();
  const maxDate = new Date();
  minDate.setDate(minDate.getDate() + 1);
  maxDate.setFullYear(maxDate.getFullYear() + 1);

  const getDayClassName = (date) => {
    const event = occupiedDates.find(occupiedDate => {
      const occupiedDateObj = new Date(occupiedDate.date.seconds * 1000);
      return date.toDateString() === occupiedDateObj.toDateString();
    });

    if (event) {
      const eventDay = new Date(event.date.seconds * 1000).getDay();
      const currentDay = date.getDay();

      if (eventDay === 5 && (currentDay === 4 || currentDay === 6)) {
        return 'blocked-date';
      } else if (eventDay === 4 && currentDay === 5) {
        return 'blocked-date';
      } else if (eventDay === 6 && currentDay === 5) {
        return 'blocked-date';
      }

      switch (event.status) {
        case 'in-progress':
        case 'awaiting-files':
          return 'occupied-date';
        case 'approved-deposit':
          return 'approved-deposit-date';
        case 'approved-paid':
        case 'completed':
          return 'approved-paid-date';
        default:
          return '';
      }
    }
    return '';
  };

  const handleDateSelect = (date) => {
    if (date.getDay() === 5) {
      notifyUser(translations[language].fridayBlocked);
    }
    setSelectedDate(date);
  };

  return (
    <div className="event-booking">
      <CloseButton />
      <form className="event-form" onSubmit={handleSubmit}>
        <h2>{translations[language].eventDetails}</h2>
        
        <select 
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
        >
          <option value="">{translations[language].selectEventType}</option>
          <option value="brit">{translations[language].brit}</option>
          <option value="barMitzva">{translations[language].barMitzva}</option>
          <option value="wedding">{translations[language].wedding}</option>
          <option value="shabbat">{translations[language].shabbus}</option>
          <option value="kidush">{translations[language].kidush}</option>
          <option value="other">{translations[language].other}</option>
        </select>
        {description === 'other' && (
          <input 
            type="text" 
            placeholder={translations[language].description}
            value={otherDescription}
            onChange={(e) => setOtherDescription(e.target.value)}
            required
          />
        )}
        <input 
          type="text" 
          placeholder={translations[language].fullName}
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
          required
        />
        <input 
          type="text" 
          placeholder={translations[language].phoneNumber}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          required
        />
        <input 
          type="email" 
          placeholder={translations[language].email}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        {occupied && dateChecked && (
          <p className="error-message">{translations[language].dateOccupied}</p>
        )}
        {selectedDate && (
          <input
            type="text"
            value={formatDate(selectedDate)}
            readOnly
            className="selected-date"
          />
        )}
        <div className="date-picker">
          <h2>{translations[language].selectDate}</h2>
          <p>{translations[language].selectDateInstruction}</p>
          <ReactDatePicker 
            inline 
            selected={selectedDate} 
            onChange={handleDateSelect}
            minDate={minDate}
            maxDate={maxDate}
            dayClassName={getDayClassName}
            calendarClassName="custom-datepicker"
          />
          <p>{occupiedDates && translations[language].redEvents}</p>
        </div>
        <p>{selectedDate?.getDay() === 6 
          ? (memberDetails.activeMembership ? translations[language].weekend + ': 1850'   : translations[language].weekend +': 2550') 
          : (memberDetails.activeMembership ? translations[language].midweek + ': 1450' : translations[language].midweek + ': 1850')} {translations[language].currency}
        </p>
        <p>{translations[language].membership}: {memberDetails.activeMembership ? translations[language].active : translations[language].inactive}</p>
        <p>{!memberDetails.activeMembership && (
            <label>
              <input
                type="checkbox"
                onChange={(e) => setPotentialMember(e.target.checked)}
              />
              {translations[language].joinMembership}
            </label>
          )}</p>
        {potentialMember && (
          <p>
            {translations[language].membershipCost}: 800 {translations[language].currency} <br />
            {translations[language].memberDiscount}: {selectedDate.getDay() === 6 ? '1850' : '1450'} {translations[language].currency} <br />
            {translations[language].cost}: {selectedDate.getDay() === 6 ? '2650' : '2250'} {translations[language].currency}
          </p>
        )}
        <button className="submit-button" type="submit" disabled={isSubmitting || occupied}>{translations[language].submit}</button>
        <button className="see-prices-button" type="button" onClick={handleSeePrices}>{translations[language].seePrices}</button>
      </form>
      <Popup ref={popupRef} />
    </div>
  );
};

export default EventBooking;
