import React from 'react';
import emailjs from 'emailjs-com';

const sendEmail = async (emailParams) => {
  const isDevMode = window.location.hostname === 'localhost';

  if (isDevMode) {
    alert(`Email to ${emailParams.to_email}\nSubject: ${emailParams.subject}\nMessage: ${emailParams.message}`);
  } else {
    //alert(`Email sent to ${emailParams.to_email}\nSubject: ${emailParams.subject}\nMessage: ${emailParams.message}`);
    emailjs.send('service_29gvpzn', 'yina_template', emailParams, 'ANG8ynOtWcelsep9B')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
      }, (error) => {
        console.error('FAILED...', error);
      });
  }
};

export default sendEmail;
