// App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import Login from './components/Login';
import PersonalArea from './components/PersonalArea';
import EventBooking from './components/EventBooking';
import TermsOfUse from './components/TermsOfUse';
import ManagerDashboard from './components/ManagerDashboard';
import Appeal from './components/Appeal';
import Donation from './components/Donation';
import PaymentInstruction from './components/PaymentInstruction';
import PrayerTimes from './components/PrayerTimes'; // New component to display prayer times
import Menu from './components/Menu'; // Import Menu
import { auth, getUserRole, checkAndAddUserToMembers } from './firebase';
import emailjs from 'emailjs-com';
import './styles/App.css';
import { useLanguage } from './LanguageContext';
import translations from './translations';

const App = () => {
  const [user, setUser] = useState(null);
  const [eventDetails, setEventDetails] = useState(null);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [isPaymentDone, setIsPaymentDone] = useState(false);
  const { language, toggleLanguage } = useLanguage();
  const [menuVisible, setMenuVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    emailjs.init('ANG8ynOtWcelsep9B');

    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const role = await getUserRole(user.email);
        setUser({ ...user, role });
        await checkAndAddUserToMembers(user); // Check and add user to members collection
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const handlePopState = (event) => {
      navigate('/');
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);

  const handleLogin = async (user) => {
    const role = await getUserRole(user.email);
    setUser({ ...user, role });
    await checkAndAddUserToMembers(user); // Check and add user to members collection
    navigate('/');
  };

  const handleFormSubmit = (details) => {
    setEventDetails(details);
    navigate('/termsOfUse');
  };

  const handleTermsAccept = () => {
    setTermsAccepted(true);
    navigate('/');
  };

 

  const handleBack = () => {
    if (isPaymentDone) {
      return;
    } else if (termsAccepted) {
      setTermsAccepted(false);
    } else if (eventDetails) {
      setEventDetails(null);
    } else {
      navigate('/');
    }
  };

  const handleLogout = () => {
    auth.signOut().then(() => {
      setUser(null);
      navigate('/');
    });
  };

  const handleHome = () => {
    navigate('/');
  };

  const handleSelectMenu = (page) => {
    setMenuVisible(false);
    navigate(`/${page}`);
  };

  if (!user) {
    return <Login onLogin={handleLogin} />;
  }

  return (
    <div className={`App ${language === 'he' ? 'rtl' : 'ltr'}`}>
      <div className="top-bar">
        <div className="dropdown">
          <button className="menu-icon" onClick={() => setMenuVisible(!menuVisible)}>&#9776;</button>
          {menuVisible && (
            <div className="dropdown-content">
              <Menu onSelect={handleSelectMenu} user={user} />
            </div>
          )}
        </div>
        <button className="home-button" onClick={handleHome}>
          Prayer Times
        </button>
        <img 
          src={user.photoURL} 
          alt="Profile" 
          className="profile-picture" 
          onClick={handleLogout} 
        />
      </div>
      <div className="container">
        <Routes>
          <Route path="/login" element={<Login onLogin={handleLogin} />} />
          <Route path="/personalArea" element={<PersonalArea user={user} />} />
          <Route path="/bookEvent" element={<EventBooking onSubmit={handleFormSubmit} savedDetails={eventDetails} user={user} onBack={() => navigate('/')} />} />
          <Route path="/termsOfUse" element={<TermsOfUse onAccept={handleTermsAccept} user={user} eventDetails={eventDetails} onBack={handleBack} />} />
          <Route path="/request" element={<Appeal user={user} onBack={() => navigate('/')} />} />
          <Route path="/donation" element={<Donation user={user} onBack={() => navigate('/')} />} />
          <Route path="/paymentInstructions" element={<PaymentInstruction user={user} event={eventDetails} />} />
          <Route path="/" element={<PrayerTimes />} /> {/* Default route to PrayerTimes */}
          {user.role === 'manager' && (
            <Route path="/managerDashboard" element={<ManagerDashboard />} />
          )}
        </Routes>
      </div>
    </div>
  );
};

export default App;
 