const translations = {
  en: {
    // Manager Dashboard
    managerDashboardTitle: "Manager Dashboard",
    managerDashboardDescription: "Manage events, payments - restricted to managers only.",
    paymentFile: "Payment File",
    saveChanges: "Save Changes",
    eventDate: "Event Date",
    fullName: "Full Name",
    phoneNumber: "Phone Number",
    email: "Email",
    description: "Description",
    contract: "Contract",
    status: "Status",
    viewContract: "View Contract",
    noFile: "No File",
    viewFile: "View File",
    awaitingFiles: "Awaiting Files",
    inProgress: "In Progress",
    approved: "Approved",
    rejected: "Rejected",
    canceled: "Canceled",
    completed: "Completed",
    changesSaved: "Changes saved successfully",
    events: "Events",
    members: "Members",
    allEvents: "All Events",
    inviteMessage: "Join Young Israel Synagogue - To join click ",
    sendInvite: "Send Invite",
    cancelRequest: " Cancel Request",
    cancelRequestError: "Only a user can rqeuest a cancelation - if you meant to cancel the event - use 'canceled' option ." ,
    cancelRequestSuccess: "Cancelation request sent successfully",
    emailApprovedDepositSubject: "Your event deposit has been approved",
    emailApprovedDepositMessage: "Your deposit has been approved. Don't forget to pay the rest no later than 3 days before the event.",
    emailApprovedPaidSubject: "Your event has been approved and paid",
    emailApprovedPaidMessage: "Your payment has been approved. Congrats!",
    emailCanceledSubject: "Your event has been canceled",
    emailCanceledMessage: "Your event has been canceled and we will let you know if you are eligible for a credit.",
    emailRejectedSubject: "Your event has been rejected",
    emailRejectedMessage: "Your event has been rejected. Please contact us for further information.",
    emailFullPaymentReceivedSubject: "Your event full payment has been received",
    emailFullPaymentReceivedMessage: "Your full payment has been received. Congrats!",
    emailAwaitingFilesSubject: "Your event is awaiting files",
    emailAwaitingFilesMessage: "Your event is awaiting files. Please upload the necessary documents to proceed.",
    changeDate: "Change Date",
    dateChangeSuccess: "Date changed successfully",
    dateChangeError: "Failed to change date",

    // Popup
    confirm: "Confirm",
    cancel: "Cancel",
 

    // Menu
    appTitle: "Young Israel",
    enter: "Enter",
    menuTitle: "Menu",
    donationTitle: "Donations",
    donationDescription: "Donate to The Young Israel Synagogue using Jgive",

    // Personal Area
    personalAreaTitle: "Personal Area",
    personalAreaDescription: "Update personal details, See Event Status",
    memberDetailsTitle: "Member Details",
    explainPayment: "Event Status - Awaiting Files. You need to transfer the amount and upload a reference or a screenshot. We will verify the transfer and approve the event booking",
    cancelConfirmation: "Are you sure you want to cancel the booking?, if you cancel the booking the deposit will not be refunded.",

    // Donations
    donationAmount: "Donation Amount",
    donationMessage: "Message (Optional)",
    donate: "Donate",
    thankYou: "Thank you for your donation of",

    // Membership
    membershipTitle: "Membership Details",
    membershipDescription: "Renew your Young Israel yearly membership, update personal details",
    membershipRenewalTitle: "Membership Renewal",
    membershipActive: "Membership Status",
    membershipStartDate: "Start Date",
    membershipEndDate: "End Date",
    renewMembership: "Renew Membership",
    noMembership: "You do not have an active membership.",
    createMembership: "Create Membership",
    saveMembership: "Update Details",
    membershipSaved: "Personal Details Updated successfully!",
    addMember: "Add Family Member",
    selectRelation: "Select Relation",
    husband: "Husband",
    wife: "Wife",
    daughter: "Daughter",
    son: "Son",
    other: "Other",
    partner: "Spouse",
    addPartner: "Add Partner",
    activateMembership: "Activate Membership",
    cancelPartner: "Cancel Add Partner",
    yes: "Membership Active",
    no: "Membership Inactive",
    skip: "Skip For Now",

    // My Events
    myEventsTitle: "My Events",
    myEventsDescription: "See your bookings, cancel your bookings",
    bookEventTitle: "Book Event",
    bookEventDescription: "Book an event at Young Israel",
    cancelBooking: "Request Cancel Booking",
    noEvents: "You do not have any bookings.",
    cancelEvent: "Cancel Event",
    confirmCancel: "Are you sure you want to cancel the booking?",
    noRefund: "The deposit will not be returned if canceled within 3 days of the event.",
    completeBooking: "Complete Booking",
    successfullyBooked: "Event successfully booked!",
    cause: "Hall Rentel for",
    cost: "Cost",
    undefined: "Undefined",

    // Appeal
    appealTitle: "Send a Request",
    appealDescription: "Send a request, can be anonymous or not",
    request: "Request",
    sendRequest: "Send Request",
    sendAnonymous: "Send Anonymously",
    emailRequired: "Valid email address is required",
    inviteSent: "Invite sent successfully",

    // General
    hello: "Hello",
    back: "Back",
    backToMenu: "Return to Menu",
    submit: "Next",
    return: "Return",
    reset: "Reset",
    languageButton: "החלף לעברית",
    transferTitle: "Payment - Bank Transfer",
    transferDescription: "Your Event has been added to the Young Israel Calendar, in order to secure the booking, the full amount must be paid no later than three days before the event. visit in",
    transfer_b: "*Currently Payment is only available through Bank Transfer",
    transferCause: "Transfer Description",
    prayerTimes: "Prayer Times",
    shacharit: "Shacharit",
    minchaGedola: "Mincha Gedola",
    mincha: "Mincha",
    arvit: "Arvit",
    shabbat: "Shabbat",
    parasha: "Parasha",
    weekdayPrayers: "Weekday Prayers",
    shabbatPrayers: "Shabbat Prayers",
    inviteMember: "Invite Member",
    address: "Address",
    openSidebar: "Show Prayer Times",
    closeSidebar: "Hide Prayer Times",
    approvedDeposit: "Approved-Deposit",
    approvedPaid: "Approved-Paid",
    payment: "Make Payment",
    payWithPayPal: "Pay with PayPal",
    bankTransfer: "Bank Transfer",
    depositPayment: "Deposit Payment",
    fullPayment: "Full Payment",
    underDevelopment: "This section is still under development - we apologize for the inconvenience",
    shabbus: "Shabbat Chatan",
    brit: "Brit/Britah",
    wedding: "Wedding/Engagement",
    kidush: "Kidush/Sheva Brachot",
    barMitzva: "Bar/Bat Mitzvah",
    selectEventType: "Select Event Type",
    membershipCost: "Membership Cost",
    memberDiscount: "Event cost with Member Discount",
    venue: "Venue",
    venueMembership: "Venue & Membership",
    remove: "Remove",
    errorMsg : "An error occurred, please try again later.",
    fileUploadedSuccess : "File uploaded successfully - please wait for confirmation.",

    // Event Booking
    selectDate: "Select a Date",
    selectDateInstruction: "Please select a date from the calendar below to book your event.",
    eventDetails: "Event Details",
    eventDescription: "Event Description",
    id: "ID",
    tz: "Teudat Zehut",
    date: "Date",
    creationDate: "Creation Date",
    chooseFutureDate: "Please choose a future date.",
    dateOccupied: "Date occupied. Please select another date.",
    noDate: "No Date",
    eventBooked: "We are pleased to inform you that your event has been successfully added to the Young Israel Calendar, in order to secure the booking, the full amount must be paid no later than three days before the event. visit in ",
    singleInProgressEvent: "You already have one event in progress - please settle payment to book a new event, if you have settled payment please wait a few hours for payment confirmation.\nIf there is still an issue you can contact us in the main menu under 'Appeal'",
    signBelow: "Please sign below to confirm the booking",
    save: "Save",
    edit: "Edit",
    actions: "Actions",
    detailsUpdated: "Details updated successfully!",
    userEmailSubject: "New Event Booking",
    memberPrices: "Member Prices: Midweek Event - 1450 ₪ / Weekend Event - 1850 ₪",
    nonMemberPrices: "Non-Member Prices: Midweek Event - 1850 ₪ / Weekend Event - 2250 ₪",
    redEvents: "Occupied Dates are marked in red",
    seePrices: "See Prices",
    projector: "The use of the projector is subject to an additional fee of 200 ₪ - Free for Members",
    weekend: "Weekend Event",
    midweek: "Midweek Event",
    joinMembership: "Would you like to Join The Young Israel Membership?",
    emptySignature: "Please sign below to confirm the booking",
    fridayBlocked: 'You have chosen a Friday morning event, If you meant to have an event on Friday evening chose Saturday.',

    // Payments
    paymentTitle: "Payments",
    paymentDescription: "Pay for memberships, events, upload documents",
    paymentInstruction: "Please mark the relevant item and proceed to payment using the button at the bottom of the page.",
    paymentComment: "If you choose to pay only the deposit, please be aware that the full amount must be paid no later than 3 days before the event date.",
    payDeposit: "Pay Deposit",
    payFullPrice: "Pay Full Price",
    deposited: "Deposited - Awaiting Full Payment",
    fully_paid: "Fully Paid",
    activate: "Proceed to Payment",
    depositRequest: "Congrats! You have successfully paid the deposit for the event. In order to confirm your booking, please pay the full price, if failed to do so until 3 days before the event date, the deposit will not be refunded and the booking will be canceled.",
    fullPaymentRequest: "Congrats! You have successfully paid the full price for the event. The key will be provided to you one day before the event. Please ensure the key is returned by 12 PM the following day to avoid any inconvenience.",
    bankTransferDetails: "Bank Transfer Details",
    bankName: "Bank: Mizrahi Tefahot",
    accountName: "Account Name: Young Israel NA",
    branch: "Branch: 483 - Karnei Shomron",
    accountNumber: "Account Number: 129502",
    dragAndDropFiles: "Drag and drop some files here, or click to select files (PDF or images only, max 5MB)",
    dropFilesHere: "Drop the files here ...",
    uploadInstructions: "In order to proceed, you need to upload the bank transfer reference file or a screenshot.",
    finishProcess: "Upload File",
    requestProcessing: "Your request is under process. You will be notified once confirmed.",
    removeFile: "Remove File",
    totalAmount: "Total Amount",
    transferAmount: "Transfer Amount",
    pay: "Proceed to Payment",
    close: "Close",
    banktransferInstructions: "Please transfer the full amount to our bank account and upload the receipt to the system to proceed with the booking - Transfer amount is ",
    currency: "NIS",
    uploadedFile: "Uploaded Files",
    bookingPayment: "We are pleased to inform you that your event has been added successfully. In order to secure the booking, the full amount must be paid no later than three days before the event.",
    transfer_a: "Please upload the receipt or a screenshot of the bank transfer",
    transfer_c: "If you require time to gather the funds or any other cause, you can upload the receipt later in the 'Personal Area'.",
    credit: "Paid",
    updatePaid: "Update Paid",
    paidValueUpdated: "Paid value updated successfully",

    // Payment Item
    type: "Type",
    amount: "Amount",
    membership: "Membership",
    partnerMembership: "Partner Membership",
    event: "Event",
    active: "Active",
    inactive: "Inactive",
    mark: "Mark",

    // Terms of Use
    termsTitle: "Terms of Use",
    accept: "I Accept",
    commitment: "By Signing below, I agree to the following terms",
    sign: "Confirm Signature",
    termsList: [
        "The key to the venue will be provided the morning of the event. Please ensure the key is returned to the lockbox by 6:00 a.m. following the conclusion of your event. Failure to do so will result in money being taken off your security deposit.",
        "The venue should be handed over in an orderly state, as it was provided. You of course are not expected to sweep or to wash the hall or the kitchen, but no large amounts of garbage should be on the floor or in the kitchen. Ensure all food and beverages are removed, and no items are left in the refrigerators.",
        "Failure to comply with any of these terms by 6:00 a.m. the following morning will result in money being deducted from your security deposit.",
        "The renter may only use the hall for the purpose stated in this contract under the heading 'Purpose of Rental/Event'.",
        "The renter cannot pay less than the agreed rental fee specified in this contract.",
        "Access to the hall for setup and organization must be coordinated in advance with the hall manager and will not be less than 8 hours before the event (subject to other events taking place).",
        "All items belonging to the renter or their associates must be removed from the hall at the end of the event.",
        "In case of a Shabbat event, any items not removed will be stored at the renter's expense and risk of loss.",
        "No one is permitted to sleep in the hall (including catering staff).",
        "A non-refundable down payment of 25% of the rental fee must be paid at the time of reservation. Without this payment, the reservation is not valid, and the date will not be held.",
        "A security deposit check for 1000 NIS, postdated for two days after the event, must be submitted prior to receiving the keys to the premises. The check will be returned to the renter after the event if the hall is left in perfect order.",
        "The keys will not be given to the renter until the balance of the payment has been paid in full. The security check must also be submitted before the keys will be made available to the renter.",
        "Failure to comply with these guidelines may result in the forfeiture of the entire deposit or a substantial part of it.",
        "In case of disputes regarding damage or any other issue related to the event, the matter will be resolved exclusively by the designated authority (Rabbi Moshe Gold or Rabbi Yitzhak Halevi).",
        "All garbage must be removed from the hall (including the dining area, kitchen, and hallway) and placed in the external garbage bins.",
        "All tables must be folded, chairs stacked, and other equipment returned to its storage area in the corners of the hall, as it was previously.",
        "No items should be attached to ceilings, walls, or floors without prior approval from the hall manager.",
        "No use of confetti, fireworks, or other pyrotechnic devices is permitted inside the hall.",
        "Smoking is prohibited in the hall, restrooms, and anywhere else inside the building.",
        "Sound levels must not exceed 85 decibels at any time. The renter is responsible for informing the band or DJ about this limit.",
        "Music must stop by 11:00 PM.",
        "The renter must ensure no disturbances to the surrounding neighborhood and must consider the neighbors.",
        "The maximum allowed capacity in the hall is 280 people. The renter must ensure that this limit is not exceeded during the event.",
        "The event must end by 12:00 AM, and all guests must vacate the hall by this time, except for catering staff.",
        "The renter is responsible for any damages to the hall or to the elevator (excluding reasonable wear and tear) caused by themselves, their guests, catering staff, band, or any other party related to the event.",
        "Please note: The hall is available AS IS! You are kindly invited to come check out the hall before you rent it, but no major changes will be made to the hall to accommodate personal requests! This includes the type of chairs available, the number and the color. The side areas of our hall are used as storage areas for our equipment and that cannot be undone. Please note as well that the bases of our tables and the tabletops are numbered. Please make sure that you match them up accordingly, otherwise they will not fit properly.",
        "Those who rent the hall for a Shabbat event must discuss the hours needed for various shabbat clocks such as lights and AC with the man in charge at our shul. You can receive this number from our hall manager.",
        "Anyone wishing to arrange private minyanim in the hall over Shabbat must first discuss the Halachic rulings with our Rav. Failure to comply with our Halachic standards will result in the complete forfeiture of your security deposit."
      ]
    
    
    
  },
  he: {
    // Manager Dashboard
    managerDashboardTitle: "כניסת מנהלים",
    managerDashboardDescription: "ניהול אירועים ותשלומים - מוגבל למנהלים בלבד.",
    paymentFile: "קובץ תשלום",
    saveChanges: "שמור שינויים",
    eventDate: "תאריך אירוע",
    fullName: "שם מלא",
    phoneNumber: "מספר טלפון",
    email: "דוא\"ל",
    description: "תיאור",
    contract: "חוזה",
    status: "סטטוס",
    viewContract: "צפה בחוזה",
    noFile: "אין קובץ",
    viewFile: "צפה בקובץ",
    awaitingFiles: "ממתין לקבצים",
    inProgress: "בתהליך",
    approved: "מאושר",
    rejected: "נדחה",
    canceled: "מבוטל",
    completed: "הושלם",
    changesSaved: "השינויים נשמרו בהצלחה",
    events: "אירועים",
    members: "חברים",
    allEvents: "כל האירועים",
    inviteMessage: "הצטרף לאתר של בית הכנסת ישראל הצעיר - להצטרפות לחץ ",
    sendInvite: "שלח הזמנה",
    cancelRequest: " בקשת ביטול",
    cancelRequestSuccess: "בקשת ביטול נשלחה בהצלחה",
    cancelRequestError: "רק משתמש יכול לבקש ביטול - אם התכוונת לבטל את האירוע - השתמש באפשרות 'מבוטל' ." ,
    emailApprovedDepositSubject: "הפיקדון שלך אושר",
    emailApprovedDepositMessage: "מזל טוב - הפיקדון שלך אושר. אל תשכח לשלם את שאר הסכום עד שלושה ימים לפני האירוע.",
    emailApprovedPaidSubject: "האירוע שלך אושר ושולם",
    emailApprovedPaidMessage: "מזל טוב - התשלום שלך אושר. ברכות!",
    emailCanceledSubject: "האירוע שלך בוטל",
    emailCanceledMessage: "האירוע שלך בוטל ונודיע לך אם יש לך זכאות לזיכוי.",
    emailRejectedSubject: "האירוע שלך נדחה",
    emailRejectedMessage: "האירוע שלך נדחה. נא ליצור קשר לפרטים נוספים.",
    emailFullPaymentReceivedSubject: "התשלום המלא שלך התקבל",
    emailFullPaymentReceivedMessage: "התשלום המלא שלך התקבל. ברכות!",
    emailAwaitingFilesSubject: "האירוע שלך ממתין לקבצים",
    emailAwaitingFilesMessage: "האירוע שלך ממתין לקבצים. נא להעלות את המסמכים הנדרשים כדי להמשיך. - יש להיכנס לקישור ולבצע פעולות תשלום",
    changeDate: "שנה תאריך",
    dateChangeSuccess: "התאריך שונה בהצלחה",
    dateChangeError: "שינוי התאריך נכשל",

    // Popup
    confirm: "אישור",
    cancel: "ביטול",

    
    // Menu
    appTitle: "בית הכנסת ישראל הצעיר",
    enter: "כניסה",
    menuTitle: "תפריט",
    donationTitle: "תרומות",
    donationDescription: "תרום לבית הכנסת ישראל הצעיר - מעבר ל Jgive",

    // Personal Area
    personalAreaTitle: "איזור אישי",
    personalAreaDescription: "עדכן פרטים אישיים, סטטוס אירועים",
    memberDetailsTitle: "פרטים אישיים",
    explainPayment: "סטטוס האירוע - ממתין לקבצים. עליכם לבצע העברה ולהעלות אסמכתא או צילום מסך של פרטי ההעברה, אנחנו נדאג לאמת את ההעברה ונאשר את ההזמנה של האירוע",
    cancelConfirmation: "האם אתה בטוח שברצונך לבטל את ההזמנה?, אם תבטל את ההזמנה הפיקדון לא יוחזר.",

    // Donations
    donationAmount: "סכום תרומה",
    donationMessage: "הודעה (אופציונלי)",
    donate: "תרום",
    thankYou: "תודה על התרומה שלך של",

    // Membership
    membershipTitle: "הפרטים שלי",
    membershipDescription: "פרטי החברות, עדכון פרטים אישיים",
    membershipRenewalTitle: "פרטי חברות",
    membershipActive: "סטטוס חברות",
    membershipStartDate: "תאריך התחלה",
    membershipEndDate: "תאריך סיום",
    renewMembership: "חדש חברות",
    noMembership: "אין לך חברות פעילה.",
    createMembership: "צור חברות",
    saveMembership: "עדכן פרטים",
    membershipSaved: "פרטי המשתמש עודכנו בהצלחה!",
    addMember: "הוסף בן משפחה",
    selectRelation: "קרבה",
    husband: "בן זוג",
    wife: "בת זוג",
    daughter: "בת",
    son: "בן",
    other: "אחר",
    partner: "בן/בת זוג",
    addPartner: "הוסף בן זוג",
    activateMembership: "הפעל חברות",
    cancelPartner: "בטל הוספת בן זוג",
    yes: "חברות פעילה",
    no: "חברות לא פעילה",
    skip: "שלם במועד מאוחר יותר",

    // My Events
    myEventsTitle: "האירועים שלי",
    myEventsDescription: "ראה את ההזמנות שלך, בטל את ההזמנות שלך",
    bookEventTitle: "הזמן אולם",
    bookEventDescription: "הזמן את אולם האירועים של ישראל הצעיר",
    cancelBooking: "בקש ביטול הזמנה",
    noEvents: "אין לך הזמנות.",
    cancelEvent: "בטל אירוע",
    confirmCancel: "האם אתה בטוח שברצונך לבטל את ההזמנה?",
    noRefund: "הפיקדון לא יוחזר אם יבוצע ביטול תוך 3 ימים מהאירוע.",
    completeBooking: "השלים את ההזמנה",
    successfullyBooked: "האירוע הוזמן בהצלחה!",
    cause: "השכרת האולם לצורך",
    cost: "סכום לתשלום",
    undefined: "לא הוגדר",

    // Appeal
    appealTitle: "פנייה לוועד",
    appealDescription: "שלח בקשה או הצעה לשיפור",
    request: "בקשה",
    sendRequest: "שלח בקשה",
    sendAnonymous: "שלח בצורה אנונימית",
    emailRequired: "נדרש כתובת אימייל תקינה",
    inviteSent: "ההזמנה נשלחה בהצלחה",

    // General
    hello: "שלום",
    back: "חזור",
    backToMenu: "חזרה לתפריט",
    submit: "הבא",
    return: "חזרה",
    reset: "איפוס",
    languageButton: "Switch to English",
    transferTitle: "תשלום - העברה בנקאית",
    transferDescription: "האירוע שלך נוסף ללוח השנה של בית הכנסת ישראל הצעיר, על מנת להבטיח את ההזמנה, יש לשלם את הסכום המלא עד שלושה ימים לפני האירוע.",
    transfer_b: "* נכון לעכשיו התשלום אפשרי רק על ידי העברה בנקאית בלבד",
    transferCause: "סיבת העברה",
    prayerTimes: "זמני תפילה",
    shacharit: "שחרית",
    minchaGedola: "מנחה גדולה",
    mincha: "מנחה קטנה",
    arvit: "ערבית",
    shabbat: "שבת",
    parasha: "פרשת השבוע",
    weekdayPrayers: "זמני תפילות חול",
    shabbatPrayers: "זמני תפילות שבת",
    inviteMember: "הזמן חבר",
    address: "כתובת",
    openSidebar: "הצג זמני תפילה",
    closeSidebar: "הסתר זמני תפילה",
    approvedDeposit: "מאושר-פיקדון",
    approvedPaid: "מאושר-שולם",
    payment: "ביצוע תשלום",
    payWithPayPal: "שלם באמצעות PayPal",
    bankTransfer: "העברה בנקאית",
    depositPayment: "תשלום פיקדון",
    fullPayment: "תשלום מלא",
    underDevelopment: "חלק זה עדיין תחת פיתוח - עמכם הסליחה",
    shabbus: "שבת חתן",
    brit: "ברית/בריתה",
    wedding: "חתונה/אירוסים",
    kidush: "קידוש/שבע ברכות",
    barMitzva: "בר/בת מצווה",
    selectEventType: "בחר סוג אירוע",
    membershipCost: "עלות חברות",
    memberDiscount: "עלות אירוע עם הנחת חבר",
    venue: "אולם",
    venueMembership: "אולם + חברות",
    remove: "הסר",
    errorMsg : "אירעה שגיאה, נסה שוב מאוחר יותר.",
    fileUploadedSuccess : "הקובץ הועלה בהצלחה - נא להמתין לאימות.",

    // Event Booking
    selectDate: "בחר תאריך",
    selectDateInstruction: "אנא בחר תאריך מהלוח שנה שלמטה כדי להזמין את האירוע שלך.",
    eventDetails: "פרטי האירוע",
    eventDescription: "תיאור האירוע",
    id: "תעודת זהות",
    tz: "תעודת זהות",
    date: "תאריך",
    creationDate: "תאריך יצירה",
    chooseFutureDate: "אנא בחר תאריך עתידי.",
    dateOccupied: "התאריך תפוס. אנא בחר תאריך אחר.",
    noDate: "תאריך לא קיים",
    eventBooked: "אנו שמחים לבשר כי האירוע שלך נוסף בהצלחה ללוח שנה של ישראל הצעיר, על מנת להבטיח את ההזמנה, יש לשלם את הסכום המלא עד שלושה ימים לפני תאריך האירוע. בקר ב",
    singleInProgressEvent: "קיים אירוע בתהליך על שמכם - נא להסדיר תשלום על מנת לקבוע אירוע חדש\nלאחר הסדרת תשלום יש להמתין כמה שעות עד לאישור התשלום.\nבמידה ועדיין יש בעיה ניתן לפנות בתפריט הראשי תחת 'פנייה לוועד'",
    signBelow: "אנא חתום לאישור ההזמנה",
    save: "שמור",
    edit: "ערוך",
    actions: "פעולות",
    detailsUpdated: "פרטים עודכנו בהצלחה!",
    userEmailSubject: "הזמנת אולם בית הכנסת ישראל הצעיר",
    memberPrices: "מחירים לחברי בית הכנסת ישראל הצעיר: אירוע באמצע השבוע – 1450 ₪ / אירוע בשבת – 1850 ₪",
    nonMemberPrices: "מחירים רגיל: אירוע באמצע השבוע – 1850 ₪ / אירוע בשבת – 2250 ₪",
    redEvents: "תאריכים תפוסים מסומנים באדום",
    seePrices: "ראה מחירים",
    projector: "שימוש במקרן כרוך בתשלום נוסף של 200 ₪ - חינם לחברי בית הכנסת",
    weekend: "אירוע שבת",
    midweek: "אירוע באמצע השבוע",
    joinMembership: "האם ברצונך להצטרף לחברות בית הכנסת ישראל הצעיר?",
    emptySignature: "חתימה ריקה - אנא חתום לאישור ההזמנה",
    fridayBlocked: 'בחרת באירוע בבוקר ביום שישי, אם התכוונת לאירוע בערב בחר ביום שבת.',
    

    // Payments
    paymentTitle: "התשלומים שלי",
    paymentDescription: "שלם עבור חברות, אירועים, הגש מסמכים",
    paymentInstruction: "יש לסמן את הסעיף הרלוונטי ולהמשיך לתשלום באמצעות הכפתור בתחתית העמוד.",
    paymentComment: "אם תבחרו לשלם את דמי הרצינות בלבד, אנא שימו לב שיש לשלם את הסכום המלא עבור השכרת האולם עד שלושה ימים לפני תאריך האירוע.",
    payDeposit: "שלם פיקדון",
    payFullPrice: "שלם מחיר מלא",
    deposited: "שולם מקדמה בלבד - ממתין לתשלום מלא",
    fully_paid: "שולם במלואו",
    activate: "מעבר לתשלום",
    depositRequest: "מזל טוב! הפיקדון שולם בהצלחה עבור האירוע. על מנת לאשר את ההזמנה, יש לשלם את המחיר המלא, אם לא יבוצע תשלום עד 3 ימים לפני האירוע, הפיקדון לא יוחזר וההזמנה תבוטל.",
    fullPaymentRequest: "מזל טוב! התשלום המלא עבור האירוע בוצע בהצלחה. המפתח יסופק לך יום לפני האירוע. אנא וודא שהמפתח יוחזר עד השעה 12 בצהריים ביום שאחרי כדי למנוע אי נוחות.",
    bankTransferDetails: "פרטי העברה בנקאית",
    bankName: "בנק: מזרחי טפחות",
    accountName: "שם חשבון: ישראל הצעיר נע",
    branch: "סניף: 483 - קרני שומרון",
    accountNumber: "מספר חשבון: 129502",
    dragAndDropFiles: "גרור ושחרר קבצים כאן, או לחץ לבחירת קבצים (PDF או תמונות בלבד, מקסימום 5MB)",
    dropFilesHere: "שחרר את הקבצים כאן ...",
    uploadInstructions: "על מנת להמשיך, עליך להעלות את קובץ ההפניה להעברה בנקאית או צילום מסך.",
    finishProcess: "העלה קובץ",
    requestProcessing: "הבקשה שלך נמצאת בתהליך. תקבל הודעה לאחר אישור.",
    removeFile: "הסר קובץ",
    totalAmount: "סכום כולל",
    transferAmount: "סכום להעברה",
    pay: "המשך לתשלום",
    close: "סגור",
    banktransferInstructions: "אנא העבירו את הסכום המלא לחשבון הבנק שלנו והעלו את הקבלה למערכת כדי להמשיך בתהליך ההזמנה - יש לבצע העברה על סך ",
    currency: "שקלים",
    uploadedFile: "קובץ שהועלה",
    bookingPayment: "אנו שמחים לבשר כי האירוע שלך נוסף בהצלחה ללוח שנה של ישראל הצעיר, על מנת להבטיח את ההזמנה, יש לשלם את הסכום המלא עד שלושה ימים לפני תאריך האירוע.",
    transfer_a: "יש להעלות את קובץ האסמכתא או צילום מסך של ההעברה בנקאית",
    transfer_c: "אם נדרש זמן לאיסוף הכסף או כל סיבה אחרת, ניתן להעלות את האסמכתא במועד מאוחר יותר ב'איזור האישי'.",
    credit: 'שולם',
    updatePaid: "עדכן סכום ששולם",
    paidValueUpdated: "עודכן בהצלחה",
    leftPaid: "נותר",

    // Payment Item
    type: "סוג",
    amount: "סכום",
    membership: "חברות",
    partnerMembership: "חברות שותף",
    event: "אירוע",
    active: "פעיל",
    inactive: "לא פעיל",
    mark: "סמן",

    // Terms of Use
    termsTitle: "תנאי שימוש",
    accept: "אני מסכים",
    commitment: "הנני מתחייב לתנאי השימוש בחתימה זאת",
    sign: "אשר חתימה",
    termsList: [
        "המפתח למקום יסופק בבוקר האירוע. אנא וודא שהמפתח יוחזר לתיבת המנעולים עד השעה 6:00 בבוקר לאחר סיום האירוע שלך. אי עמידה בתנאי זה תגרום לניכוי כסף מהפיקדון שלך.",
        "המקום צריך להימסר במצב מסודר כפי שסופק. אינך מצופה לטאטא או לשטוף את האולם או את המטבח, אך לא צריכים להיות כמויות גדולות של זבל על הרצפה או במטבח. וודא שכל המזון והמשקאות הוסרו ושלא נותרו פריטים במקררים.",
        "אי עמידה באחד מתנאים אלו עד השעה 6:00 בבוקר שלמחרת תגרום לניכוי כסף מהפיקדון שלך.",
        "השוכר רשאי להשתמש באולם רק למטרה שנקבעה בחוזה זה תחת הכותרת 'מטרת ההשכרה/אירוע'.",
        "השוכר לא יכול לשלם פחות מהסכום המוסכם שנקבע בחוזה זה.",
        "הגישה לאולם לצורך הקמה וארגון חייבת להיות מתואמת מראש עם מנהל האולם ולא פחות מ-8 שעות לפני האירוע (בכפוף לאירועים אחרים שמתקיימים).",
        "כל הפריטים השייכים לשוכר או לשותפיו חייבים להיות מוסרים מהאולם בסוף האירוע.",
        "במקרה של אירוע בשבת, כל הפריטים שלא הוסרו יאוחסנו על חשבון השוכר ובסיכון לאובדן.",
        "אף אחד לא רשאי לישון באולם (כולל צוות ההסעדה).",
        "תשלום מקדמה לא ניתן להחזרה של 25% מדמי ההשכרה חייב להתבצע במועד ההזמנה. ללא תשלום זה, ההזמנה אינה תקפה והתאריך לא יישמר.",
        "צ'ק פיקדון של 1000 ש\"ח, שמועבר ליומיים לאחר האירוע, חייב להיות מוגש לפני קבלת המפתחות למקום. הצ'ק יוחזר לשוכר לאחר האירוע אם האולם יישאר במצב מושלם.",
        "המפתחות לא יינתנו לשוכר עד שהתשלום הכולל ישולם במלואו. צ'ק הביטחון חייב גם הוא להיות מוגש לפני שהמפתחות יהיו זמינים לשוכר.",
        "אי עמידה בהנחיות אלו עשויה לגרום להפקעת הפיקדון כולו או חלק ניכר ממנו.",
        "במקרה של מחלוקות בנוגע לנזק או כל עניין אחר הקשור לאירוע, העניין ייפתר באופן בלעדי על ידי הסמכות המיועדת (הרב משה גולד או הרב יצחק הלוי).",
        "כל האשפה חייבת להיות מוסרת מהאולם (כולל אזור האכילה, המטבח והמסדרון) ומונחת בפחי האשפה החיצוניים.",
        "כל השולחנות חייבים להיות מקופלים, הכיסאות נערמים, וציוד אחר מוחזר לאזור האחסון שלו בפינות האולם, כפי שהיה קודם לכן.",
        "אין לחבר פריטים לתקרות, לקירות או לרצפות ללא אישור מראש ממנהל האולם.",
        "אין להשתמש בקונפטי, זיקוקים או מכשירים פירוטכניים אחרים בתוך האולם.",
        "עישון אסור באולם, בשירותים ובכל מקום אחר בתוך המבנה.",
        "רמות הצליל חייבות לא לעלות על 85 דציבלים בכל עת. האחריות על השוכר להודיע ללהקה או לדי.ג'יי על הגבלה זו.",
        "המוזיקה חייבת להיפסק עד השעה 11:00 בלילה.",
        "השוכר חייב לוודא שאין הפרעות לשכונה הסובבת וחייב להתחשב בשכנים.",
        "הקיבולת המרבית המותרת באולם היא 280 איש. האחריות על השוכר לוודא שמגבלה זו לא תעלה במהלך האירוע.",
        "האירוע חייב להסתיים עד השעה 12:00 בלילה, וכל האורחים חייבים לעזוב את האולם עד לשעה זו, למעט צוות ההסעדה.",
        "השוכר אחראי לכל נזק לאולם או למעלית (למעט בלאי סביר) שנגרם על ידי עצמו, אורחיו, צוות ההסעדה, הלהקה או כל צד אחר הקשור לאירוע.",
        "יש לציין: האולם זמין כפי שהוא! אנו מזמינים אותך לבוא לבדוק את האולם לפני שאתה משכיר אותו, אך לא ייעשו שינויים משמעותיים באולם כדי להתאים לבקשות אישיות! זה כולל את סוג הכיסאות הזמינים, הכמות והצבע. אזורי הצד של האולם שלנו משמשים כאזורי אחסון לציוד שלנו וזה לא ניתן לשינוי. יש לציין גם כי בסיסי השולחנות והפלטות העליונות שלנו ממוספרים. אנא ודא שאתה מתאם אותם כראוי, אחרת הם לא יתאימו כראוי.",
        "מי ששוכר את האולם לאירוע בשבת חייב לדון בשעות הדרושות עבור שעוני שבת כמו אורות ומיזוג עם האיש האחראי בבית הכנסת שלנו. ניתן לקבל את מספר זה ממנהל האולם שלנו.",
        "כל מי שרוצה לארגן מניינים פרטיים באולם בשבת חייב תחילה לדון עם הרב על ההנחיות ההלכתיות. אי עמידה בסטנדרטים ההלכתיים שלנו תגרום להפקעת הפיקדון כולו."
      ]
  
  }
};

export default translations;
