import React, { useState } from 'react';
import { useLanguage } from '../LanguageContext';
import translations from '../translations';
import sendEmail from './EmailSender.js';
import '../styles/Appeal.css';
import CloseButton from '../components/CloseButton.js';


const Appeal = ({ onBack, user }) => {
  const { language } = useLanguage();
  const [name, setName] = useState(user.displayName || '');
  const [request, setRequest] = useState('');
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [error, setError] = useState('');


  const handleSubmit = (e) => {
    e.preventDefault();
    if (!request.trim()) {
      setError(translations[language].requestRequired);
      return;
    }
    setError('');
    const templateParams = {
      subject: "User Request",
      user_name: isAnonymous ? 'Anonymous' : name,
      to_email: 'youngisraelofnevealiza@gmail.com',
      message: isAnonymous ? 'Anonymous  ' + request : name + ' sent a request: ' + request +  'from ' +  user.email,
      linkTitle: translations[language].managerDashboardTitle,
      link: 'https://yina.org/managerDashboard'
    };
    sendEmail(templateParams);
  };

  return (
    <div className="appeal">
      <CloseButton/>
      <h2>{translations[language].appealTitle}</h2>
      <div className="anonymous-checkbox">
        <label>
          <input 
            type="checkbox" 
            checked={isAnonymous} 
            onChange={() => setIsAnonymous(!isAnonymous)} 
          />
          {translations[language].sendAnonymous}
        </label>
      </div>
      <form onSubmit={handleSubmit}>
        {!isAnonymous && (
          <input 
            type="text" 
            placeholder={translations[language].name} 
            value={name} 
            onChange={(e) => setName(e.target.value)} 
          />
        )}
        <textarea 
          placeholder={translations[language].request} 
          value={request} 
          onChange={(e) => setRequest(e.target.value)} 
          required
        />
        {error && <p className="error">{error}</p>}
        <button className='submit-button' type="submit">{translations[language].sendRequest}</button>
      </form>
    </div>
  );
};

export default Appeal;
