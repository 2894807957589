import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import '../styles/CloseButton.css'; // Ensure you have appropriate styles
import close_icon from '../icons8-close-80.png';

const CloseButton = ({ verify }) => {
  const navigate = useNavigate();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleClick = () => {
    if (verify) {
      setShowConfirmation(true);
    } else {
      navigate('/');
    }
  };

  const handleConfirmYes = () => {
    navigate('/');
  };

  const handleConfirmNo = () => {
    setShowConfirmation(false);
  };

  return (
    <div className="close-button-container">
      <button className="close-button" onClick={handleClick}>
        <img src={close_icon} alt="Close" />
      </button>
      {showConfirmation && (
        <div className="confirmation-message">
          <p>Are you sure you want to leave in the middle of the process?</p>
          <button className="confirm-button" onClick={handleConfirmYes}>Yes</button>
          <button className="confirm-button" onClick={handleConfirmNo}>No</button>
        </div>
      )}
    </div>
  );
};

export default CloseButton;
