import React from 'react';
import { useLanguage } from '../LanguageContext';
import translations from '../translations';
import prayerTimes from '../prayerTimes.json'; // Import the JSON file
import '../styles/PrayerTimes.css';

const PrayerTimes = () => {
  const { language } = useLanguage();

  const currentDate = new Date(); // Use the current date and time

  // Helper function to parse date string
  const parseDate = (dateStr) => {
    const [day, month, year] = dateStr.split('/');
    return new Date(`${year}-${month}-${day}`);
  };

  // Find the next upcoming Shabbat (Saturday) and its corresponding parasha
  const currentWeek = prayerTimes.specificDates.find(week => {
    const dateParts = week.date.split('/');
    const shabbatDate = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);

    return currentDate <= shabbatDate;
  });

  const weekdays = currentWeek ? currentWeek.times : {};
  const shabbat = prayerTimes.shabbat;

  return (
    <div className="prayer-times-container">
      <div className="prayer-times">
        {currentWeek ? (
          <>
            <h1>{translations[language].parasha} : {currentWeek.parashaHebrew} - {currentWeek.parasha}</h1>
            <h2>{translations[language].prayerTimes}</h2>
            <div className="prayer-section">
              <h3>{translations[language].weekdayPrayers}</h3>
              <ul>
                <li>{translations[language].shacharit}: {prayerTimes.weekdays.shacharit.join(', ')}</li>
                <li>{translations[language].mincha}: {prayerTimes.weekdays.mincha.join(', ')}, {weekdays.secondMincha}</li>
                <li>{translations[language].arvit}: {weekdays.firstMaariv}</li>
              </ul>
            </div>
            <div className="prayer-section">
              <h3>{translations[language].shabbatPrayers}</h3>
              <ul>
                <li>{translations[language].shacharit}: {shabbat.shacharit.join(', ')}</li>
              </ul>
            </div>
          </>
        ) : (
          <h1>{translations[language].noParasha}</h1>
        )}
      </div>
    </div>
  );
};

export default PrayerTimes;
