// components/Donation.js
import React, { useState } from 'react';
import { useLanguage } from '../LanguageContext';
import translations from '../translations';
import '../styles/Donation.css';
import Popup, { usePopup } from '../components/Popup.js';


const Donation = ({ onBack }) => {
  const { language } = useLanguage();
  const [amount, setAmount] = useState('');
  const [message, setMessage] = useState('');
  const { notifyUser } = usePopup();

  const handleDonate = () => {
    notifyUser(`${translations[language].thankYou} ${amount}`);
    setAmount('');
    setMessage('');
    onBack();
  };

  return (
    <div className="donation">
      <h2>{translations[language].donationTitle}</h2>
      <form onSubmit={(e) => { e.preventDefault(); handleDonate(); }}>
        <input
          type="number"
          placeholder={translations[language].donationAmount}
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          required
        />
        <textarea
          placeholder={translations[language].donationMessage}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <button type="submit">{translations[language].donate}</button>
      </form>
    </div>
  );
};

export default Donation;
