import React from 'react';
import { useLanguage } from '../LanguageContext';
import translations from '../translations';
import '../styles/Menu.css';

const Menu = ({ onSelect, user }) => {
  const { language, toggleLanguage } = useLanguage();

  return (
    <div className="menu">
      <ul>
        <li onClick={toggleLanguage}>
          {translations[language].languageButton}
        </li>
        {user.role === 'manager' && (
          <li onClick={() => onSelect('managerDashboard')}>
            {translations[language].managerDashboardTitle}
          </li>
        )}
        <li className='bolder' onClick={() => onSelect('bookEvent')}>
          {translations[language].bookEventTitle}
        </li>
        <li onClick={() => onSelect('personalArea')}>
          {translations[language].personalAreaTitle}
        </li>
        <li onClick={() => onSelect('request')}>
          {translations[language].appealTitle}
        </li>
        <li>
          <a href='https://www.jgive.com/new/he/ils/charity-organizations/3981' target='_blank' rel='noreferrer noopener'>
            {translations[language].donationTitle}
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Menu;
